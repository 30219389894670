import React from 'react';
import { Logo, Wrap } from '@leafwell/components';
import HeadingBody from './heading-body';

const Loader: React.FC<{ className?: string; fullHeight?: boolean }> = ({
  className = '',
  fullHeight = true,
}) => (
  <Wrap
    className={[
      'text-center justify-items-center content-center !gap-y-3',
      fullHeight ? 'h-screen' : '',
      className,
    ].join(' ')}
  >
    <Logo variant="icon" width={fullHeight ? 80 : 30} />
    <HeadingBody level="5" title="loading..." />
  </Wrap>
);

export default Loader;
